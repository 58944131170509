import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 'env(safe-area-inset-bottom)',
    paddingLeft: 'env(safe-area-inset-left)',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 'env(safe-area-inset-right)',
    },
    flex: 1,
    overflow: 'auto',
    overflowScrolling: "auto",
    WebkitOverflowScrolling: "touch",
  },
  list: {
      color: '#4a4a4a'
  }
});


class CategoryList extends React.Component {
  state={
    categories: []
  }

  componentDidMount(){
    const {ReactGA} = this.props;
    ReactGA.pageview('/categories');
  }

  render(){
    const {classes, categories} = this.props;
  return (
    <div className={classes.root}>
      {categories && categories.map((item, index)=><ListItem alignItems="flex-start" button onClick={()=>this.props.handleServiceToggle(item)} style={{color: '#4a4a4a'}} key={index}>
        <ListItemText primary={`${item}`} />
          <KeyboardArrowRightIcon />
      </ListItem>)}
    </div>
  )};
}

export default withStyles(styles)(CategoryList)
