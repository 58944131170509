import React from 'react';
import { withStyles, fade } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import InputBase from '@material-ui/core/InputBase';
import dataService from '../../../services/dataService';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
        height: 'calc(100vh - 64px - env(safe-area-inset-top))'
    },
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
    list: {
    flex:1,
    paddingBottom: 'env(safe-area-inset-bottom)',
    paddingLeft: 'env(safe-area-inset-left)',
    [theme.breakpoints.down('xs')]: {
        paddingRight: 'env(safe-area-inset-right)'
    },
    overflow: 'auto',
    overflowScrolling: "auto",
    WebkitOverflowScrolling: "touch",
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    // flex:0,
    zIndex: 111,
    width: 340,
    paddingTop: 'calc(10px + env(safe-area-inset-top))',
    paddingLeft: 'env(safe-area-inset-left)',
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
        paddingRight: 'env(safe-area-inset-right)',
        width: '100%',
        justifyContent: 'space-between'
    },
    minHeight: 65
},
mapIcon:{
    display: 'block',
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
    color: "#fff"
},
title: {
    color: '#fff',
    fontSize: 18
},
item: {
    borderBottom: "1px solid #f1f1f1"
},
search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
      flex: 1,
      marginRight: 10
    },
    color: "#fff"
  },
searchIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
},
titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1
},
clearIcon: {
    width: theme.spacing(3),
    height: '100%',
    position: 'absolute',
    top: -5,
    right: 15
},
inputRoot: {
    color: 'inherit',
    width: '100%',
},
inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create('width'),
    width: '100%',
},
});


class ServicesList extends React.Component {
    state={
        allServices: [],
        services: [],
        query: '',
        search: false
    }

    onClickBackIcon=()=>{
        this.props.handleServiceBack();
    }

    onClickMapIcon=()=>{
        const {ReactGA} = this.props;
        ReactGA.event({
            category: 'Map',
            action: 'Map Pressed',
            label: 'Services'
          });
        this.props.handleMapViewToggle(this.state.services)
    }

    componentDidMount(){

        const {ReactGA} = this.props;
        if(this.props.serviceQuery){
            this.setState({
                search: true,
                query: this.props.serviceQuery
            })
        }
        dataService.getServicesByCategory(this.props.categoryID).then(res=>{
          if(this.props.serviceQuery){
              this.setState({
                services: this.search(this.props.serviceQuery, res),
                allServices: res
              })
          } else {
            this.setState({
                services: res,
                allServices: res
            })
            this.props.updateSearchResults(res, "")
          }
        })
        ReactGA.pageview('/services');
      }

      onClickItem=(id, name)=>{
        this.props.closeDrawer();
        this.props.history.push("/service/"+encodeURIComponent(id)+"/"+encodeURIComponent(name))
      }

      search=(query,list)=>{
        const strings = query.split(" ");
        const results = list.filter(item=>{
            return (
                strings.map(str=>item["keywords"].toLowerCase().includes(str.toLowerCase())).every(match=>match) || 
                strings.map(str=>item["service_category"].toLowerCase().includes(str.toLowerCase())).every(match=>match) ||
                strings.map(str=>item["agency_name"].toLowerCase().includes(str.toLowerCase())).every(match=>match)
            )
        })
        this.props.updateSearchResults(results.slice(0,20), query)
        return results.slice(0,20)
    }


      handleInput=(event)=>{
        const query = event.target.value;
        this.setState({
            query,
            services: this.search(query, this.state.allServices)
        })
      }

      onClickSearchIcon=()=>{
        this.setState({
            search: true
        })
      }

      onClearIconClick=()=>{
          this.setState({
              query: '',
              services: this.state.allServices
          })
      }

    serviceSort(a, b) {
        const an = a.agency_name.toLowerCase();
        const bn = b.agency_name.toLowerCase();
        if (an == bn) return a.id - b.id;
        else return an > bn ? 1 : -1;
    }

    render(){
        const {classes} = this.props;
        return (
            <div className={classes.root}>
            <div className={classes.head}>
                <IconButton onClick={this.onClickBackIcon}>
                    <ArrowBackIcon className={classes.InfoIcon} style={{color: '#fff'}} />
                </IconButton>
                {this.state.search ? <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                    placeholder="Search…"
                    autoFocus
                    autoComplete="off"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    name="query"
                    value={this.state.query}
                    onChange={(e)=>this.handleInput(e)}
                    inputProps={{ 'aria-label': 'search' }}
                    />
                    <div className={classes.clearIcon}>
                        <IconButton onClick={this.onClearIconClick}> 
                            <ClearIcon style={{color:"#fff"}} />
                        </IconButton>
                    </div>
                </div>:< div className={classes.titleContainer}>
                    <div className={classes.title}>{this.props.categoryID}</div>
                    <IconButton onClick={this.onClickSearchIcon}>
                        <SearchIcon style={{color: '#fff'}} />
                    </IconButton>
                </div>}
                <Button onClick={this.onClickMapIcon} className={classes.mapIcon}>
                    Map
                </Button>
            </div>
            <div className={classes.list}>
                {this.state.services && this.state.services.sort(this.serviceSort).map((item, index)=><ListItem className={classes.item} button onClick={()=>this.onClickItem(item.agency_id, item.agency_name)} style={{color: '#4a4a4a'}} key={index}>
                    <ListItemText 
                        primary={`${item.agency_name}`}
                        secondary = {[item.street, item.suburb_town, ",", item.postcode].join(" ").replace(/(\s*,\s*)+/g, ", ").replace(/,\s*$/, "")} // add space, replace whitespace before or after comma and remove trailing comma
                    />
                    <KeyboardArrowRightIcon />
                </ListItem>)}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ServicesList);
