import { openDB } from 'idb';
import {googleAnalyticsCode} from "../config/constants";
import ReactGA from 'react-ga';
const api = "https://irefervic.com/Service1.svc/";
ReactGA.initialize(googleAnalyticsCode, {
    gaOptions: {
      siteSpeedSampleRate: 100
    }
  });  

function createDB(){
    const db = openDB('iRefer', 2, {
        upgrade(db, oldVersion, newVersion, transaction) {
            if (oldVersion === 1) {
                db.deleteObjectStore('keyValuePairs');
                db.deleteObjectStore('services');
            }
            db.createObjectStore('keyValuePairs');
            const serviceStore = db.createObjectStore('services', { keyPath: 'id', autoIncrement: true });
            serviceStore.createIndex('service_category', 'service_category');
            serviceStore.createIndex('agency_id', 'agency_id');
            serviceStore.createIndex('keywords', 'keywords'); 
        }
    });
    return db;
}

function fetchData(){
    return new Promise(function(resolve, reject) {
        const url = api+"GetExcelData";
        fetch(url, {method: "POST"}).then(res=>res.json())
        .then(async res=>{
            const services = res.response.responsepacket.IReferList;
            createDB().then(async db=>{
                const tx = db.transaction('services', 'readwrite');
                tx.store.clear();
                services.forEach((service, index) => {
                    if(service.agency_name.length>0){
                        service.service_category = service.service_category.replace(/^\s+|\s+$/g, '');
                        service.agency_id = index.toString();
                        tx.store.add(service);
                    }
                });
                await tx.done;
            });
            resolve(res);
        }).catch(err=>{
            console.log(err)
            reject(err)
        })
    })
}

export default class dataService{
    static getData(){
        return new Promise(function(resolve, reject) {
            const url = api+"GetVersion";
            fetch(url, {method: "POST"}).then(res=>res.json())
            .then(async res=>{
                const version = res.response.responsepacket.FileName.replace("Book", "").replace(".xlsx", "");
                const date = new Date(res.response.responsepacket.Date);
                createDB().then(async db=>{
                    const lastSyncedVersion = await db.get('keyValuePairs', 'lastSyncVersion');
                    const lastSyncedDate = await db.get('keyValuePairs', 'lastSyncedDate');
                    if(lastSyncedVersion && lastSyncedVersion === version ){
                        ReactGA.event({
                            category: 'Sync',
                            action: 'Data up-to-date',
                            nonInteraction: true
                          });
                        let allservices = await db.getAll('services');
                        let categories = [...new Set(allservices.map(s => s['service_category']))];
                        const formattedCategories = categories.map(category=>category.replace(/^\s+|\s+$/g, ''));
                        const sortedCategories = formattedCategories.sort((a,b) => (a.toLowerCase()>b.toLowerCase())*2-1);
                        resolve({services: allservices, categories: sortedCategories, lastSyncedDate: lastSyncedDate});
                    } else {
                        fetchData().then(async res=>{
                            await db.put('keyValuePairs', version, 'lastSyncVersion');
                            await db.put('keyValuePairs', date, 'lastSyncedDate');
                            let allservices = await db.getAll('services');
                            let categories = [...new Set(allservices.map(s => s['service_category']))];
                            const formattedCategories = categories.map(category=>category.replace(/^\s+|\s+$/g, ''));
                            const sortedCategories = formattedCategories.sort((a,b) => (a.toLowerCase()>b.toLowerCase())*2-1);
                            ReactGA.event({
                                category: 'Sync',
                                action: 'Data synced',
                                nonInteraction: true
                              });
                            resolve({services: allservices, categories: sortedCategories, lastSyncedDate: date});
                        }).catch(err=>{
                            ReactGA.exception({
                                description: 'Error with sync',
                                fatal: true
                              });
                            reject(err)
                        })
                    }
                });
            }).catch(err=>{
                ReactGA.exception({
                    description: 'Error with sync',
                    fatal: true
                  });
                console.log(err)
                reject(err)
            })
        })
    }

    static async getAllCategories(){
        return new Promise(function(resolve, reject) {
            createDB().then(async db=>{
                var allservices = await db.getAll('services');
                let categories = [...new Set(allservices.map(s => s['service_category']))];
                const formattedCategories = categories.map(category=>category.replace(/^\s+|\s+$/g, ''));
                const sortedCategories = formattedCategories.sort((a,b) => (a.toLowerCase()>b.toLowerCase())*2-1);
                resolve({categories: sortedCategories});
            })
        })
    }

    static async getAllServices(){
        return new Promise(function(resolve, reject) {
            createDB().then(async db=>{
                let allservices = await db.getAll('services');
                let categories = [...new Set(allservices.map(s => s['service_category']))];
                const formattedCategories = categories.map(category=>category.replace(/^\s+|\s+$/g, ''));
                const sortedCategories = formattedCategories.sort((a,b) => (a.toLowerCase()>b.toLowerCase())*2-1);
                resolve({services: allservices, categories: sortedCategories});
            })
        })
    }

    static async getServiceDetails(id){
        return new Promise(function(resolve, reject) {
            createDB().then(async db=>{
                var service = await db.getFromIndex('services', 'agency_id', id);
                resolve(service);
            }).catch(err=>{
                console.log(err)
            })
        })
    }

    static async getServicesByCategory(category){
        return new Promise(function(resolve, reject){
            createDB().then(async db=>{
                var allbycat = await db.getAllFromIndex('services', 'service_category', category);
                resolve(allbycat);
            })
        })
    }

}