import React from 'react';
import { withStyles, fade } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

class Search extends React.Component{
    state={
        query: '',
        services: []
    }
    onClickBackIcon=()=>{
        this.props.hideSearch()
    }

    onClickMapIcon=()=>{
        const {ReactGA} = this.props;
        ReactGA.event({
            category: 'Map',
            action: 'Map Pressed',
            label: 'Search'
          });
        this.props.handleMapViewToggle(this.state.services, this.state.query, "map")
    }

    onClickItem=(item)=>{
        this.props.closeDrawer();
        this.props.hideMapView(this.state.services, this.state.query, this.props.mobileDevice ? "search" : "map");
        this.props.history.push("/service/"+item.agency_id+"/"+item.agency_name)
      }

    onClearIconClick=()=>{
        this.setState({
            query: ''
        })
    }

    search=(query,list)=>{
        const strings = query.split(" ");
        const results = list.filter(item=>{
            return (
                strings.map(str=>item["keywords"].toLowerCase().includes(str.toLowerCase())).every(match=>match) || 
                strings.map(str=>item["service_category"].toLowerCase().includes(str.toLowerCase())).every(match=>match) ||
                strings.map(str=>item["agency_name"].toLowerCase().includes(str.toLowerCase())).every(match=>match)
            )
        })
        this.props.updateSearchResults(results.slice(0,20), query)
        return results.slice(0,20)
    }

    handleInput=(event)=>{
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        })
        const query = value;
        if(query.length>2){
            this.setState({
                query,
                services: this.search(query, this.props.services)
            })
        }
    }

    componentDidMount(){
        if(this.props.query){
            if(this.props.query.length>2){
                this.setState({
                    services: this.search(this.props.query, this.props.services)
                })
            }
            this.setState({
                query: this.props.query
            })
        }
        const {ReactGA} = this.props;
        ReactGA.pageview('/search');
    }

    render(){
        const {classes} = this.props;
        return(<div className={classes.main}>
            <div className={classes.head}>
                <IconButton onClick={this.onClickBackIcon}>
                    <ArrowBackIcon className={classes.back} />
                </IconButton>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                    placeholder="Search…"
                    autoFocus
                    autoComplete="off"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    name="query"
                    value={this.state.query}
                    onChange={(e)=>this.handleInput(e)}
                    inputProps={{ 'aria-label': 'search' }}
                    />
                    <div className={classes.clearIcon}>
                        <IconButton onClick={this.onClearIconClick}> 
                            <ClearIcon style={{color:"#fff"}} />
                        </IconButton>
                    </div>
                </div>
                <Button onClick={this.onClickMapIcon} className={classes.mapIcon}>
                    Map
                </Button>
            </div>
            <div className={classes.container}>
            {this.state.services.map((item, index)=><ListItem className={classes.item} button onClick={()=>this.onClickItem(item)} style={{color: '#4a4a4a'}} key={index}>
                <ListItemText 
                    primary={`${item.agency_name}`} 
                    secondary={`${item.street} ${item.suburb_town && ', '+item.suburb_town} ${item.postcode && ', '+item.postcode}`}
                />
                <KeyboardArrowRightIcon />
            </ListItem>)}
            </div>
        </div>)
    }
}

const styles = theme => ({
    main: {
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 64px - env(safe-area-inset-top))'
        },
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    head:{
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        height: 'calc(64px + env(safe-area-inset-top))',
        paddingTop: 'env(safe-area-inset-top)',
        paddingLeft: 'env(safe-area-inset-left)',
        display: 'flex',
        alignItems: 'center',
        width: 340,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 'env(safe-area-inset-right)',
            width: '100%'
        },
        zIndex: 111,
        minHeight: 65
    },
    container: {
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingLeft: 'env(safe-area-inset-left)',

        [theme.breakpoints.down('xs')]: {
            paddingRight: 'env(safe-area-inset-right)'
        },
        overflow: 'auto',
        overflowScrolling: "auto",
        WebkitOverflowScrolling: "touch",
    },
    back: {
        color: '#fff',
        padding: 0
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        marginRight: 5,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(0),
          width: 'auto',
          flexGrow: 1
        },
      },
    searchIcon: {
        width: theme.spacing(5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mapIcon: {
        color: '#fff',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            display: 'none'
          }
    },
    clearIcon: {
        width: theme.spacing(3),
        height: '100%',
        position: 'absolute',
        top: -5,
        right: 15
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create('width'),
    width: '100%',
    },
    item: {
        borderBottom: "1px solid #f1f1f1"
    }
})

export default withStyles(styles)(Search)