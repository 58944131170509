import React from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

class MapSearchBox extends React.Component {

  constructor(props){
    super(props);
    this.searchRef = React.createRef();
  }

  render() {
    const {gotoPlace, ...rest} = this.props;
    return <>
    <Input
      inputRef={e => this.searchRef = e}
      placeholder="Search area..."
      classes={{formControl: this.props.classes.root}}
      disableUnderline
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      {...rest}
    />
    </>
  }
  onPlacesChanged = () => {
    if (this.props.gotoPlace) {
      this.props.gotoPlace(this.searchBox.getPlaces());
    }
  }
  componentDidMount() {
    const {googlemaps} = this.props; 
    var input = ReactDOM.findDOMNode(this.searchRef);
    this.searchBox = new googlemaps.places.SearchBox(input);
    this.searchBox.addListener('places_changed', this.onPlacesChanged);
  }
  componentWillUnmount() {
    const {googlemaps} = this.props; 
    googlemaps.event.clearInstanceListeners(this.searchBox);
  }
}

const styles = theme => ({
    formControl: {
        flex: 1
    },
    root: {
        backgroundColor: "#fff",
        height: 40,
        paddingLeft: 5,
        flex: 1
    }
})

export default withStyles(styles)(MapSearchBox)