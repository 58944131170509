import React from 'react';
import {withRouter} from 'react-router'
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';

import Contact from "./components/Contact";
import Content from "./components/Content";
import dataService from '../../services/dataService';

class Details extends React.Component{
    constructor(props){
        super(props);
        this.head = React.createRef()
    }

    state={
        serviceDetails:{}
    }

    componentDidMount(){
        const path = this.props.match.params.tail ? this.props.match.params.id + "/" + this.props.match.params.tail : this.props.match.params.id;
        const id = parseInt(path) + ''; // just get the number from the start of the path (as a string)
        dataService.getServiceDetails(id).then(res=>{
            this.setState({
                serviceDetails: res,
                id: id
            })
        })
        const {ReactGA} = this.props;
        ReactGA.pageview('/serviceDetail');
    }

    UNSAFE_componentWillUpdate(nextProps, state){
        const path = nextProps.match.params.tail ? nextProps.match.params.id + "/" + nextProps.match.params.tail : nextProps.match.params.id;
        const id = parseInt(path) + ''; // just get the number from the start of the path (as a string)
        if(id !== state.id){
            dataService.getServiceDetails(id).then(res=>{
                this.setState({
                    serviceDetails: res,
                    id: id
                })
            })
        }
    }

    render(){
        const {classes, lastState, mobileDevice, services, ReactGA} = this.props;
        return(<div className={classes.root}>
            <div className={classes.head} ref={this.head}>
                <div className={classes.detail} style={{justifyContent: mobileDevice ? "flex-start" : "space-between"}}>
                    {mobileDevice && <IconButton onClick={()=>{this.props.history.goBack(); mobileDevice && lastState === "map" ? this.props.handleMapViewToggle(services) : this.props.showServiceList(services, null, lastState)}} className={classes.back} >
                        <ArrowBackIcon />
                    </IconButton>}
                    {this.state.serviceDetails.agency_name}
                    {!mobileDevice && <IconButton onClick={()=>{this.props.closeDetails(); this.props.showServiceList(services, null, lastState)}} className={classes.close} >
                        <CloseIcon />
                    </IconButton>}
                </div>
            </div>
            <div className={classes.container}>
                <Content ReactGA={ReactGA} serviceDetails={this.state.serviceDetails} />
                <Contact ReactGA={ReactGA} serviceDetails={this.state.serviceDetails} />
            </div>
        </div>)
    }
}

const styles = theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 64px - env(safe-area-inset-top))'
        },
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position:'relative'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        flex: 1,
        overflow: 'hidden'
    },
    back: {
        marginRight: 10,
        padding: 0,
        color: "#fff"
    },
    close: {
        right: 0,
        color: "#fff"
    },
    head: {
        flex:0,
        zIndex: 111,
        width: '100%'
    },
    detail: {
        fontSize: 18,
        position: 'relative',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        paddingTop: 'max(15px, env(safe-area-inset-top))',
        paddingRight: 'max(15px, env(safe-area-inset-right))',
        paddingLeft: 'max(15px, env(safe-area-inset-left))',
        paddingBottom: 8,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 0,
            paddingLeft: 15,
            paddingBottom:2
          }
    }
})

export default withStyles(styles)(withRouter(Details))
