import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Home from "./views/Home";

const theme = createMuiTheme({
  palette: {
    primary: {main: "#5a3f99"},
    white: "#fff"
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
        <Switch>
            <Route exact path="/service/:id/:tail?" component={Home} />
            <Route exact path="/" component={Home} />
            <Route exact path="/category/:category" component={Home} />
            <Route exact path="/search" component={Home} />
        </Switch>
      </Router>
      </ThemeProvider>
  );
}

export default App;
