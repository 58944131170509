import React from 'react';
import {
  Switch, Route, withRouter
} from "react-router-dom";

import { withStyles, fade } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InfoIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CategoryHead from './components/CategoryHead';
import CategoryList from './components/CategoryList';
import ServicesList from "./components/ServicesList";
import Details from '../Details';
import About from './components/About';
import Search from '../Search';
import MapView from './components/MapView';
import ReactGA from 'react-ga';
import dataService from '../../services/dataService';
import {googleAnalyticsCode, mapArea} from "../../config/constants";

const drawerWidth = 340;

ReactGA.initialize(googleAnalyticsCode, {
  gaOptions: {
    siteSpeedSampleRate: 100
  }
});

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    flexDirection: 'column'
  },
  appBar: {
    zIndex: 11111,
    boxShadow: 'none',
    display: 'none',
    flex:0,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      paddingTop: 'env(safe-area-inset-top)',
      paddingRight: 'env(safe-area-inset-right)',
      paddingLeft: 'env(safe-area-inset-left)',
    },
  },
  mainWrapper: {
    flex: 1,
    display: 'flex',
    flexDirextion: 'row',
  },
  drawer: {
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 64px - env(safe-area-inset-top))'
    },
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    backgroundColor: theme.palette.primary.main,
    zIndex: 111,
    width: drawerWidth,
    paddingLeft: 'max(15px, env(safe-area-inset-left))',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 'max(15px, env(safe-area-inset-right))',
      width: '100%',
      top:0,
    },
    minHeight: 65,
    // flex:0
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontSize: '1.3em'
  },
  menuButton: {
    marginRight: 0,
  },
  drawerPaper: {
    position: 'relative',
    width: '100%',
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
    display: 'none',
    height: 64,
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  appTitle:{
    fontSize: 18,
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    color: '#fff'
  },
  logo: {
    width: 35,
    marginRight: 10
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

class Home extends React.Component{

  state = {
    mobileDevice: false,
    mobileOpen: false,
    searchOpen: false,
    serviceListOpen: false,
    serviceList: [],
    mapViewOpen: false,
    categoryID: null,
    data: null,
    error: null,
    sidebar: null,
    syncing: false,
    categories: [],
    query: '',
    lastState: 'home',
    lastSyncedDate: new Date(),
    position: {
      center: mapArea.center,
      zoom: mapArea.zoom
    }
  }

  handleDrawerToggle = () => {
    if (!this.state.mobileOpen) {
      window.history.pushState(null, "", window.location.href);
    }
    this.setState({
      mobileOpen: !this.state.mobileOpen
    })
  };

  closeDrawer = () => {
    this.setState({
      mobileOpen: false
    })
  };

  openSearch = () => {
    this.setState({
      searchOpen: true,
      mapViewOpen: true,
      query: null,
      serviceList: []
    })
  };

  hideSearch = () =>{
    this.setState({
      searchOpen: false,
      mapViewOpen: false,
      query: null,
      serviceListOpen: false
    })
    if(!this.state.mobileDevice){
      this.props.history.push('/'); 
    }
  }

  handleSearchRestore=(services)=>{
    this.setState({
      searchOpen: true,
      services: this.state.services,
      mapViewOpen: false,
      lastState: 'search'
    })
  }

  handleServiceToggle=(id)=>{
    this.setState({
      serviceListOpen: !this.state.serviceListOpen,
      categoryID: id
    })
    this.props.history.push("/category/"+encodeURIComponent(id));
  }

  updateSearchResults=(services, query)=>{
    this.setState({
      serviceList: services,
      query: query,
      lastState: "search"
    })
  }

  handleMapViewToggle=(services, query, lastState)=>{
    if (this.state.mobileOpen) {
      window.history.pushState(null, "", window.location.href);
      // console.log("history push state")
    }
    this.setState({
      serviceList: services || [],
      serviceListOpen: false,
      mapViewOpen: true,
      mobileOpen: this.state.mobileDevice,
      searchOpen: false,
      query: query || this.state.query,
      lastState: lastState || this.state.lastState
    })
  }

  hideMapView=(services, query, lastState)=>{
    this.setState({
      mapViewOpen: false,
      query: query || this.state.query,
      serviceList: services || [],
      lastState: lastState || this.state.lastState
    })
  }

  showServiceList=(services, id, lastState, serviceQuery)=>{
    this.setState({
      mobileOpen: this.state.mobileDevice,
      categoryID: id || this.state.categoryID,
      serviceList: services || [],
      serviceListOpen: true,
      mapViewOpen: lastState === "map" ? true: false,
      lastState: 'service',
      serviceQuery
    })
  }

  handleServiceBack=()=>{
    this.setState({
      serviceListOpen: false,
      mapViewOpen: false
    })
    if(this.state.mobileDevice){
      this.props.history.goBack();
    } else {
      this.props.history.push('/'); 
      this.hideMapView();
    }
  }

  closeDetails=()=>{
    if(this.state.categoryID){
      this.handleServiceToggle(this.state.categoryID);
    }
  }
  
  drawer=()=>{
    const {searchOpen, serviceListOpen, categories, mapViewOpen, mobileDevice, serviceList, services, categoryID, query, lastState} = this.state;
    const {handleDrawerToggle, closeDrawer, hideSearch, openSearch, handleSearchRestore, handleMapViewToggle, hideMapView, handleServiceToggle, showServiceList, handleServiceBack} = this;
    const {classes, history} = this.props;
    return(
      searchOpen?<Search ReactGA={ReactGA} history={history} services={services} mobileDevice={mobileDevice} query={query} hideSearch={hideSearch} handleMapViewToggle={handleMapViewToggle} closeDrawer={closeDrawer} hideMapView={hideMapView} updateSearchResults={this.updateSearchResults} />:
      serviceListOpen?<ServicesList ReactGA={ReactGA} categoryID={categoryID} closeDrawer={closeDrawer} handleServiceToggle={handleServiceToggle} handleServiceBack={handleServiceBack} handleMapViewToggle={handleMapViewToggle} history={history} updateSearchResults={this.updateSearchResults} serviceQuery={this.state.serviceQuery} mobileDevice={mobileDevice} />:
      mapViewOpen?<MapView ReactGA={ReactGA} categoryID={categoryID} lastState={lastState} services={serviceList} query={query} showServiceList={showServiceList} handleServiceToggle={handleServiceToggle} hideMapView={hideMapView} handleServiceBack={handleServiceBack} handleMapViewToggle={handleMapViewToggle} closeDrawer={closeDrawer} handleSearchRestore={handleSearchRestore} updatePosition={this.updatePosition} position={this.state.position} />:
      <div className={classes.drawer}>
        <div className={classes.toolbarIcon}>
          <div className={classes.appTitle}>
            <CategoryHead ReactGA={ReactGA} history={history} handleDrawerToggle={handleDrawerToggle} openSearch={openSearch} />
          </div>
        </div>
        <Divider />
        <CategoryList ReactGA={ReactGA} history={history} categories={categories} handleServiceToggle={handleServiceToggle} />
      </div>
    )
    };

  componentWillUnmount() {
    window.onpopstate = undefined;
    this.currentlocation = null;
  }

  currentlocation = null;
  componentDidMount(){

    if (this.currentlocation === null) this.currentlocation = window.location.hash;
    //window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      // console.log("Going from", this.currentlocation, "to", window.location.hash);
      // console.log(this.props.history);
      // console.log(this.state);
      if ((!this.state.mobileDevice || this.state.serviceListOpen || this.state.mapViewOpen) && this.currentlocation.toLowerCase().startsWith('#/category') && window.location.hash == "#/") {
        if (this.state.mobileDevice && this.state.mapViewOpen) {
          this.hideMapView();
        }
        else {
          this.setState({
            serviceListOpen: false,
            mapViewOpen: false
          })
        }
      }
      else if (this.currentlocation.toLowerCase().startsWith('#/service') && window.location.hash.toLowerCase().startsWith("#/category") && this.state.mobileDevice) {
        // console.log("I'm here, damnit");
        //crap, what do I do here?
        // this.state.mobileDevice && this.state.lastState === "map" ? this.handleMapViewToggle(this.state.services) : this.showServiceList(this.state.services, null, this.state.lastState)
        // if (this.state.lastState !== "map") {
        //   this.hideMapView();
        //if (this.state.mobileDevice && this.state.lastState === "map") this.handleMapViewToggle(this.state.services);
        let newState = {};
        if (!this.state.mobileOpen) newState.mobileOpen = true;
        if (this.state.lastState === "map" && this.state.mapViewOpen == false) {
          newState.mapViewOpen = true;
        }
        // console.log("newState", Object.keys(newState).length, newState);
        if (newState && Object.keys(newState).length > 0) this.setState(newState);
        // }
      }
      else if (this.currentlocation == "#/" && window.location.hash == "#/" && this.state.mobileDevice) {
        //closing the drawer
        this.setState({ mobileOpen: false });
      }
      else if (this.currentlocation.toLowerCase().startsWith('#/category') && window.location.hash.toLowerCase().startsWith("#/category") && this.state.mobileOpen && this.state.mapViewOpen) {

        this.setState({ mapViewOpen: false });
      }
      this.currentlocation = window.location.hash;
    };
    
    this.setState({
      syncing: true,
    })

    if(window.innerWidth<600){
      this.setState({
        mobileDevice: true
      })
    }

    window.addEventListener('resize', this.updateDimensions);
    dataService.getData().then(res=>{
      this.setState({
          services: res.services,
          categories: res.categories,
          lastSyncedDate: res.lastSyncedDate,
          syncing: false
      })
    }).catch(err=>{
      this.setState({
        syncing: false,
        error: true
      })
    })
  }

  updateDimensions=()=>{
    if(window.innerWidth<600){
      this.setState({
        mobileDevice: true
      })
    } else {
      this.setState({
        mobileDevice: false
      })
    }
  }

  updatePosition=(position)=>{
    this.setState({
      position
    })
  }

  setSidebar=()=>{
    this.setState({
      sidebar: true
    })
  }

  render(){
    const {mobileOpen, services, lastSyncedDate, syncing} = this.state;
    const {handleDrawerToggle, drawer, setSidebar, hideMapView} = this;
    const {classes} = this.props;
    return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="sticky" className={classes.appBar} >
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title}>
            iRefer: Service Information and Referral Database
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open home"
            edge="start"
            onClick={()=>{this.props.history.push('/'); this.hideMapView()}}
            className={classes.menuButton}
          >
            <InfoIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.mainWrapper}>
        <nav aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            
            <Drawer
              variant="temporary"
              anchor={'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer()}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer()}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
            {this.state.mapViewOpen ? <MapView ReactGA={ReactGA} query={this.state.query} categoryID={this.state.categoryID} services={this.state.serviceList} handleServiceToggle={this.handleServiceToggle} handleServiceBack={this.handleServiceBack} handleMapViewToggle={this.handleMapViewToggle} hideMapView={hideMapView} updatePosition={this.updatePosition} position={this.state.position} /> :
          <Switch>
              <Route path="/service/:id/:tail?" render={() => <Details ReactGA={ReactGA} showServiceList={this.showServiceList} lastState={this.state.lastState} handleMapViewToggle={this.handleMapViewToggle} mobileDevice={this.state.mobileDevice} services={this.state.serviceList} closeDetails={this.closeDetails} />} />
              <Route path="/category/:category" render={() => <MapView ReactGA={ReactGA} showServiceList={this.showServiceList} handleServiceToggle={this.handleServiceToggle} handleServiceBack={this.handleServiceBack} handleMapViewToggle={this.handleMapViewToggle} services={this.state.serviceList} updatePosition={this.updatePosition} position={this.state.position} />} />
              <Route path="/" render={()=> <About ReactGA={ReactGA} syncing={syncing} lastSyncedDate={lastSyncedDate} setSidebar={setSidebar} data={services} handleDrawerToggle={handleDrawerToggle} />} />
          </Switch>
          }
        </main>
      </div>
    </div>
  )};
}

export default withStyles(styles)(withRouter(Home));
