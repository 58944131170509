import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Divider, Link } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LinkIcon from '@material-ui/icons/Link';

class Contact extends React.Component{
    isValidUrl=(string)=>{
        try {
          new URL(string);
        } catch (_) {
          return false;  
        }
      
        return true;
    }
    render() {
        const {classes, serviceDetails, ReactGA} = this.props;
        let addressString = serviceDetails.street ? serviceDetails.street.trim() : '';
        if (serviceDetails.suburb_town) addressString += (addressString.length > 0 ? ', ' : '') + serviceDetails.suburb_town;
        if (serviceDetails.postcode) addressString += (addressString.length > 0 ? ', ' : '') + serviceDetails.postcode;

        let query = serviceDetails.map && serviceDetails.map.length > 0
            ? encodeURI(serviceDetails.map)
            : serviceDetails.street && serviceDetails.street.length > 0
                ? `${encodeURI(serviceDetails.agency_name)}, ${encodeURI(serviceDetails.street)}`
                : null;

        if(serviceDetails.street)
        switch (serviceDetails.street.toLowerCase()) {
            case "contact service":
            case "for closest location click web link below":
            case "multiple locations":
            case "various locations":
            case "school outreach":
                query = null;
                addressString = serviceDetails.street ? serviceDetails.street.trim() : '';
                if (serviceDetails.suburb_town) addressString += (addressString.length > 0 ? '\n' : '') + serviceDetails.suburb_town;
                break;
            default: break;
        }

        if(serviceDetails.suburb_town)
        switch (serviceDetails.suburb_town.toLowerCase()) {
            case "online service":
            case "state-wide":
            case "online/telephone service":
            case "telephone service":
                query = null;
                addressString = serviceDetails.street ? serviceDetails.street.trim() : '';
                if (serviceDetails.suburb_town) addressString += (addressString.length > 0 ? '\n' : '') + serviceDetails.suburb_town;
                break;
            default: break;
        }

        let website = serviceDetails.website;
        let hostname;

        if(website){
            website = website.replace(/[^a-zA-Z0-9-_/:.<>#%{}|^~`;@=+"?&]/g, '');
            if(!website.includes("http")){
                website = `http://${website}`
            }
            if(this.isValidUrl(website)){
                hostname = new URL(website).hostname.replace('www.','');
            }
        }
        return(<div className={classes.content}>
            {website && this.isValidUrl(website) && <div className={classes.website}>
                <div className={classes.item}>
                    <ReactGA.OutboundLink
                    eventLabel="websiteLink"
                    className={classes.link}
                    to={website}
                    target="_blank"
                    >
                        <Avatar className={classes.greyavatar}>
                            <LinkIcon className={classes.webicon} />
                        </Avatar>
                    </ReactGA.OutboundLink>
                    <div className={classes.details}>
                        <div className={classes.title}>
                            Website
                        </div>
                    </div>
                </div>
                <div className={classes.para}>
                    <ReactGA.OutboundLink
                        className={classes.link}
                        eventLabel="websiteLink"
                        to={website}
                        target="_blank"
                        >
                            {hostname}
                    </ReactGA.OutboundLink>
                </div>
                <Divider className={classes.divider}/>
            </div>}
            {addressString && addressString.length>0 && <div className={classes.list}>
                <div className={classes.item}>
                    {query && query.length > 0 ?
                        <ReactGA.OutboundLink
                            className={classes.link}
                            eventLabel="addressLink"
                            to={`https://www.google.com/maps/search/${query}`}
                            target="_blank"
                        >
                            <Avatar className={classes.avatar}>
                                <MapIcon className={classes.icon} />
                            </Avatar>
                        </ReactGA.OutboundLink>
                    : <Avatar className={classes.avatar}>
                        <MapIcon className={classes.icon} />
                    </Avatar>}
                    <div className={classes.details}>
                        <div className={classes.title}>
                            Address
                        </div>
                    </div>
                </div>
                <div className={classes.para}>
                    {query && query.length > 0 ?
                        <ReactGA.OutboundLink
                            className={classes.link}
                            eventLabel="addressLink"
                            to={`https://www.google.com/maps/search/${query}`}
                            target="_blank"
                        >
                            {addressString}
                        </ReactGA.OutboundLink>
                        : <em>{addressString}</em>}
                </div>
                <Divider className={classes.divider}/>
            </div>}
            {serviceDetails.phone && serviceDetails.phone.length>0 && <div className={classes.list}>
            <div className={classes.item}>
            <ReactGA.OutboundLink
                className={classes.link}
                eventLabel="phoneLink"
                to={`tel:${serviceDetails.phone.replace(' ', '')}`}
                >
                <Avatar className={classes.avatar}>
                    <PhoneIcon className={classes.icon}/>
                </Avatar>
            </ReactGA.OutboundLink>
                <div className={classes.details}>
                    <div className={classes.title}>
                        Phone
                    </div>
                </div>
            </div>
            <div className={classes.para}>
                <ReactGA.OutboundLink
                    eventLabel="phoneLink"
                    className={classes.link}
                    to={`tel:${serviceDetails.phone.replace(' ', '')}`}
                    >
                        {serviceDetails.phone}
                </ReactGA.OutboundLink>
            </div>
            <Divider className={classes.divider}/>
            </div>}
            {serviceDetails.phone2 && serviceDetails.phone2.length>0 && <div className={classes.list}>
                <div className={classes.item}>
                    <Link className={classes.link} href={`tel:${serviceDetails.phone2.replace(' ', '')}`}>
                    <Avatar className={classes.avatar}>
                        <PhoneIcon className={classes.icon}/>
                    </Avatar>
                    </Link>
                    <div className={classes.details}>
                        <div className={classes.title}>
                            Alternate Phone
                        </div>
                    </div>
                </div>
                <div className={classes.para}>
                    <ReactGA.OutboundLink
                        eventLabel="phoneLink"
                        className={classes.link}
                        to={`tel:${serviceDetails.phone2.replace(' ', '')}`}
                        >
                            {serviceDetails.phone2}
                        </ReactGA.OutboundLink>
                </div>
                <Divider className={classes.divider}/>
            </div>}
            {serviceDetails.email && serviceDetails.email.length>0 &&<div className={classes.list}>
                <div className={classes.item}>
                <Link className={classes.link} href={`mailto:${serviceDetails.email}`}>
                <Avatar className={classes.avatar}>
                    <EmailIcon className={classes.icon}/>
                </Avatar>
                </Link>
                <div className={classes.details}>
                    <div className={classes.title}>
                        Email
                    </div>
                </div>
            </div>
            <div className={classes.para}>
                    <ReactGA.OutboundLink
                        eventLabel="emailLink"
                        className={classes.link}
                        to={`mailto:${serviceDetails.email}`}
                        >
                            <div>{serviceDetails.email}</div>
                        </ReactGA.OutboundLink>
            </div>
            </div>}
        </div>)
    }
}

const styles = theme => ({
    content:{
        backgroundColor: '#c1ccc4',
        maxWidth: 350,
        width: '40%',
        // minHeight: `calc(100vh - 55px)`,
        paddingBottom: 'env(safe-area-inset-bottom)',
        [theme.breakpoints.up('sm')]: {
            // minHeight: 'calc(100vh - 129px)'
        },
        overflow: 'auto',
        overflowScrolling: "auto",
        WebkitOverflowScrolling: "touch",
        height:'100%'
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 'max(10px, env(safe-area-inset-right))'
    },
    list: {
        paddingTop: 10
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        zIndex:0
    },
    greyavatar: {
        backgroundColor: '#c1ccc4',
    },
    icon: {
        color: '#fff'
    },
    webicon: {
        color: theme.palette.primary.main
    },
    details: {
        marginLeft: 10
    },
    title: {
        fontSize: 18
    },
    para: {
        paddingTop: 5,
        paddingBottom: 2,
        wordBreak: 'break-word',
        paddingLeft: 10,
        paddingRight: 10
    },
    link:{
        color: 'inherit',
        cursor: 'pointer'
    },
    divider: {
        marginTop: 10
    },
    website: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        paddingTop: 10,
        position: 'sticky',
        top: 0,
        zIndex: 5
    }
})

export default withStyles(styles)(Contact)
