import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

class CategoryHead extends React.Component{
    
    onClickSearchIcon=()=>{
        const {ReactGA} = this.props;
        ReactGA.event({
            category: 'Categories',
            action: 'Search Pressed'
          });
        this.props.openSearch()
    }

    onClickInfoIcon=()=>{
        this.props.handleDrawerToggle()
        this.props.history.push("/");
    }

    render(){
        const {classes} = this.props;
        return(<div className={classes.container}>
            <IconButton onClick={this.onClickInfoIcon} className={classes.infoIconButton}>
                <InfoIcon className={classes.InfoIcon} style={{color: '#fff'}} />
            </IconButton>
            <div className={classes.title}>Categories</div>
            <IconButton onClick={this.onClickSearchIcon}>
                <SearchIcon style={{color: '#fff'}} />
            </IconButton>
        </div>)
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft:'env(safe-area-inset-left)',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 'env(safe-area-inset-top)',
            paddingRight: 'env(safe-area-inset-right)',
        }
    },
    infoIcon: {
        color: '#fff'
    },
    infoIconButton: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
           },
    }
})

export default withStyles(styles)(CategoryHead)