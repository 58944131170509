import React from 'react';
import { withStyles } from '@material-ui/core/styles';

class Content extends React.Component{
    render(){
        const {classes, serviceDetails} = this.props;
        return(<div className={classes.content}>
           {serviceDetails.service_description && serviceDetails.service_description.length>0 && <div>
           <div className={classes.title}>
               Service Description
           </div>
           <div className={classes.para}>
                {serviceDetails.service_description}
           </div></div>}
           {serviceDetails.opening_hours && serviceDetails.opening_hours.length>0 && <div>
           <div className={classes.title}>
               Opening Hours
           </div>
           <div className={classes.para}>
                {serviceDetails.opening_hours}
           </div>
           </div>}
           {serviceDetails.after_hours_appointments && serviceDetails.after_hours_appointments.length>0 && <div>
           <div className={classes.title}>
               After Hours Appointments
           </div>
           <div className={classes.para}>
                {serviceDetails.after_hours_appointments}
           </div>
           </div>}
           {serviceDetails.availability_waiting_period && serviceDetails.availability_waiting_period.length>0 && <div>
           <div className={classes.title}>
               Availability/Waiting Period
           </div>
           <div className={classes.para}>
                {serviceDetails.availability_waiting_period}
           </div>
           </div>}
           {serviceDetails.fees && serviceDetails.fees.length>0 && <div>
           <div className={classes.title}>
               Fees
           </div>
           <div className={classes.para}>
                {serviceDetails.fees}
           </div>
           </div>}
           {serviceDetails.eligibility_conditions && serviceDetails.eligibility_conditions.length>0 && <div>
           <div className={classes.title}>
               Eligibility Conditions
           </div>
           <div className={classes.para}>
                {serviceDetails.eligibility_conditions}
           </div>
           </div>}
           {serviceDetails.outpost_locations && serviceDetails.outpost_locations.length>0 && <div>
           <div className={classes.title}>
               Outpost Locations
           </div>
           <div className={classes.para}>
                {serviceDetails.outpost_locations}
           </div>
           </div>}
           {serviceDetails.referral_process && serviceDetails.referral_process.length>0 && <div>
           <div className={classes.title}>
               Referral Process
           </div>
           <div className={classes.para}>
                {serviceDetails.referral_process}
           </div>
           </div>}
           {serviceDetails.staff_qualifications && serviceDetails.staff_qualifications.length>0 && <div>
           <div className={classes.title}>
               Staff Qualifications
           </div>
           <div className={classes.para}>
                {serviceDetails.staff_qualifications}
           </div>
           </div>}
           {serviceDetails.website && serviceDetails.website.length>0 && <div>
           <div className={classes.title}>
               Website
           </div>
           <div className={classes.para}>
                {serviceDetails.website}
           </div>
           </div>}
           {serviceDetails.relevant_websites && serviceDetails.relevant_websites.length>0 && <div>
           <div className={classes.title}>
               Relevant Websites
           </div>
           <div className={classes.para}>
                {serviceDetails.relevant_websites}
           </div>
           </div>}
        </div>)
    }
}

const styles = theme => ({
    content:{
        flex: 1,
        padding: 10,
        marginBottom: 50,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 'max(10px, env(safe-area-inset-left))'
          },
        overflow: 'auto',
        overflowScrolling: "auto",
        WebkitOverflowScrolling: "touch",
        height:'100%'
    },
    title: {
        fontSize: 18,
        color: theme.palette.primary.main,
        width: '100%'
    },
    para: {
        paddingTop: 0,
        paddingBottom: 15,
        wordBreak: "break-word"
    }
})

export default withStyles(styles)(Content)