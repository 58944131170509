import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button, CircularProgress } from '@material-ui/core';

class About extends React.Component {

    state = {
        loading: false
    }

    componentDidMount() {
        const { ReactGA } = this.props;
        ReactGA.pageview('/about');

        // load the about html file (from the public folder)
        fetch("https://irefervic.com/about.html")
        .then(response => {
            return response.text()
        })
        .then(data => {
            document.querySelector("#content").innerHTML = data;
        });
    }

    getFormattedDate=(date)=>{
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
        let month = monthNames[date.getMonth()];
        let day = String(date.getDate());
        let year = date.getFullYear();
        return day  + ' ' + month + ' '+ year;
    }

    render() {
        const { classes, lastSyncedDate, syncing } = this.props;
        const { error } = this.state;
        return (
            <div className={classes.main}>
                <div className={classes.head}>
                    <div className={classes.heading}>
                        iRefer: Service Information and Referral Database
                    </div>
                </div>
                <div className={classes.wrapper}>
                    <div className={classes.identity}>
                        <img src="./images/vic.png" alt="vic" />
                    </div>
                    <div className={classes.downloadContainer}>
                        <a className={classes.downloadLink} href="./dist/iRefer.dmg" ><img src="./images/mac-download-badge.png" alt="vic" /></a>
                        <a className={classes.downloadLink} href="./dist/iRefer Setup.exe" ><img src="./images/windows-download-badge.png" alt="vic" /></a>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            disabled={syncing || error}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.props.handleDrawerToggle}
                            endIcon={<ChevronRightIcon />}
                        >
                            Get Started
                        </Button>
                    </div>
                    <div id="content" className={classes.content}>
                        {/* <div dangerouslySetInnerHTML={this.iframe()} /> */}
                    </div>
            </div>
            <div className={classes.footer}>
                {syncing ? <div className={classes.loadingContent}>
                    <CircularProgress style={{ color: '#fff', height: 20, width: 20 }} />
                        Syncing data with server
                        </div> : error ? <div className={classes.error}>Information could not be retrieved</div> :
                        <div className={classes.footerContent}>Last updated at {this.getFormattedDate(lastSyncedDate)}</div>}
            </div>
        </div>)
    }
}

const styles = theme => ({
    main: {
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 64px - env(safe-area-inset-top))'
        },
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    head: {
        padding: 20,
        fontSize: 18,
        color: '#fff',
        minHeight: 65,
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        paddingTop: 'max(20px, env(safe-area-inset-top))',
        paddingRight: 'max(20px, env(safe-area-inset-right))',
        paddingLeft: 'max(20px, env(safe-area-inset-left))',
        // flex: 0,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '0'
        },
        zIndex:5
    },
    wrapper: {
        flex: 1,
        overflow: 'auto',
        overflowScrolling: "auto",
        WebkitOverflowScrolling: "touch",
    },
    content: {
        flex: 1,
        padding: 10,
        maxWidth: 960,
        margin: 'auto',
        marginBottom: 100,
        paddingRight: 'max(10px, env(safe-area-inset-right))',
        paddingLeft: 'max(10px, env(safe-area-inset-left))',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 10
        }
    },
    heading: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    identity: {
        marginTop: 20,
        textAlign: 'center',
        alignSelf: 'center'
    },
    title: {
        fontSize: 18,
        fontWeight: '600'
    },
    center: {
        fontStyle: 'italic',
        textAlign: 'center'
    },
    para: {
        paddingTop: 5,
        paddingBottom: 5
    },
    button: {
        borderRadius: 20,
        textAlign: 'center',
        alignSelf: 'center',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    buttonContainer: {
        textAlign: 'center',
        margin: 30
    },
    downloadContainer: {
        textAlign: 'center',
        margin: 30,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    downloadLink: {
        paddingLeft: 5,
        paddingRight: 5
    },
    footer: {
        width: '100%'
    },
    footerContent: {
        justifyContent: 'center',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(60px + env(safe-area-inset-bottom))',
        backgroundColor: theme.palette.primary.main,
        paddingRight: 'env(safe-area-inset-right)',
        paddingLeft: 'env(safe-area-inset-left)', 
        paddingTop: 0,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '0'
        },
    },
    loadingContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ff8800',
        color: '#fff',
        height: 60,
    },
    error: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#d3574e',
        color: '#fff',
        height: 60,
    }
})

export default withStyles(styles)(About)