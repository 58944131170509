import React, { useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import GoogleMapReact from 'google-map-react';
import dataService from '../../../services/dataService';
import { withRouter } from 'react-router';
import {mapArea} from "../../../config/constants";
import MapSearchBox from './MapSearchBox';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
      color: '#4a4a4a'
  },
  head: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 15px',
        ...theme.mixins.toolbar,
        minHeight: 65,
        paddingTop: 'env(safe-area-inset-top)',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 'max(15px, env(safe-area-inset-left))',
            paddingRight: 'env(safe-area-inset-right)'
          }
    },
    title: {
        fontSize: 18
    },
    action: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
          },
    },
    mapContainer:{
        width: '100%',
        marginTop: 'env(safe-area-inset-top)',
        height: 'calc(100vh - 65px - env(safe-area-inset-top))',
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 128px - env(safe-area-inset-top))'
          },
    },
    searchbar:{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        margin: 10,
        display: "flex"
    },
    locationButton: {
        background: "white",
        width: 40,
        height: 40,
        marginRight: 70,
        marginLeft: 10,
        "&:hover": {
            background: "#fff"
          },
    },
});

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#fff',
      width: 250,
      color: '#000',
      fontSize: theme.typography.pxToRem(10),
      border: '1px solid #dadde9',
      marginLeft: -3
    },
    arrow:{
        color: '#fff'
    }
  }))(Tooltip);

function Marker (props){
    const [open, setOpen] = React.useState(false);
    const [click, setClick] = React.useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleClose = () => {
        if(!click){
            setOpen(false);
        }
    };

    const handleOpen = () => {
        if(!props.click){
            setOpen(true);
        }
    };

    const handleClick=()=>{
        setClick(true);    
        setOpen(true);
        props.clickEvent();
    }

    function useOutsideAlerter(ref) {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setOpen(false);
            }
          }
      
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          document.addEventListener("touchstart", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
          };
        }, [ref]);
      }

    return(<div>
    <HtmlTooltip
        arrow
        interactive
        placement="top"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
            disablePortal: true,
          }}
        ref={wrapperRef}
        title={
          <React.Fragment >
            <div style={{cursor: 'pointer'}}
             onClick={()=>{props.hideMapView && props.hideMapView(props.services, props.query || props.category, "map"); props.closeDrawer && props.closeDrawer(); props.history.push("/service/"+props.service.agency_id+"/"+props.service.agency_name)}}
             >
                <Typography color="textPrimary" style={{fontSize: 14}} >{props.service.agency_name}</Typography>
                <Typography color="textSecondary" style={{fontSize: 12}}>{props.service.map}</Typography>
            </div>
          </React.Fragment>
        }
      >
        <img
            onClick={handleClick}
            alt="position"
            src="./images/Pin.png" style={{width: 30}} 
        />
      </HtmlTooltip>
</div>);
}

class MapView extends React.Component {

    constructor(props) {
        super(props);
        this.state= {
            services: [],
            selectedService: '',
            click: false,
            apiReady: false,
        }
        this.searchbar = React.createRef();
    }

    onClickBackIcon=()=>{
        if(this.props.match.params.category){
            this.props.showServiceList(this.state.services, this.props.match.params.category);
        } else {
            this.props.handleSearchRestore(this.state.services);
        }
    }

    onClickListIcon=()=>{
        const {ReactGA} = this.props;
        if(this.props.match.params.category){
            ReactGA.event({
                category: 'Map',
                action: 'List Pressed',
                label: 'Services'
              });
            this.props.showServiceList(this.state.services, this.props.match.params.category, "", this.props.query);
        } else {
            ReactGA.event({
                category: 'Map',
                action: 'List Pressed',
                label: 'Search'
              });
            this.props.handleSearchRestore(this.state.services);
        }
    }

    componentDidMount(){
        if(!this.props.services && this.props.match && this.props.match.params.category){
            dataService.getServicesByCategory(decodeURIComponent(this.props.match.params.category)).then(res=>{
                this.setState({
                    services: res
                })
            })
        } else {
            this.setState({
                services: this.props.services
            })
        }
        const {ReactGA} = this.props;
        ReactGA.pageview('/map');
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.match.params.category !== this.props.match.params.category){
            dataService.getServicesByCategory(decodeURIComponent(nextProps.match.params.category)).then(res=>{
                this.setState({
                    services: res
                })
            })
        }
        if(nextProps.services){
            this.setState({
                services: nextProps.services
            })
        }
    }

    hideTooltip=()=>{
        this.setState({
            openTooltip: false
        })
    }

    showTooltip=()=>{
        this.setState({
            openTooltip: true
        })
    }

    clickEvent=()=>{
        this.setState({
            click: true
        })
    }

    apiHasLoaded=(map, googlemaps)=>{
        if (map && googlemaps) {
            map.controls[googlemaps.ControlPosition.TOP_LEFT].push(this.searchbar.current);
            this.setState({
                apiReady: true,
                map: map,
                googlemaps: googlemaps
            });
      }
   }

   getCurrentPosition=()=>{
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position)=>{
            const newPosition = {
                center: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                zoom: 12
            }
            this.props.updatePosition(newPosition);
        },(err)=>{
            console.log("Couldn't fetch your position")
        });
      }
   }

   onPlacesChanged=(location)=>{
       if(location.length>0){
           const position = location[0];
           const newPosition = {
                center: {
                    lat: position.geometry.location.lat(),
                    lng: position.geometry.location.lng()
                },
                zoom: 12
            }
           this.props.updatePosition(newPosition)
       }
   }

   onMapChange=(map)=>{
    const newPosition = {
        center: map.center,
        zoom: map.zoom
    }
    this.props.updatePosition(newPosition)
   }

    render(){
        const {classes, history, closeDrawer, hideMapView, position} = this.props;
        const services = this.state.services;
        const { apiReady, googlemaps, map} = this.state;
        const {onPlacesChanged} = this;
        return (
            <div className={classes.root}>
            <div className={classes.head}>
                <IconButton className={classes.action} onClick={this.onClickBackIcon}>
                    <ArrowBackIcon className={classes.InfoIcon} />
                </IconButton>
                <div className={classes.title}>{this.props.query || decodeURIComponent(this.props.match.params.category || '')}</div>
                <IconButton className={classes.action} onClick={this.onClickListIcon}>
                    <ListIcon  />
                </IconButton>
            </div>
            <div className={classes.mapContainer}>
                <React.Fragment >
                    <div className={classes.searchbar} ref={this.searchbar} >
                    {apiReady && (<>
                        <MapSearchBox
                            map={map}
                            googlemaps={googlemaps}
                            gotoPlace={onPlacesChanged}
                        />
                        <IconButton onClick={this.getCurrentPosition} className={classes.locationButton}>
                            <MyLocationIcon />
                        </IconButton>
                        </>)}
                    </div>
                </React.Fragment>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAJJh8h-GXyVz0FZDDixZFrc_6fGB_ccIg", libraries: ['places'] }}
                    defaultCenter={mapArea.center}
                    defaultZoom={mapArea.zoom}
                    center={position.center}
                    zoom={position.zoom}
                    onChange={this.onMapChange}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                    >
                        {services.map(service=>
                         service.Latitude && service.Latitude.length>0 && parseFloat(service.Latitude) && service.Longitude && service.Longitude.length>0  && parseFloat(service.Longitude)&& <Marker
                            key={service.id}
                            lat= {service.Latitude}
                            lng= {service.Longitude}
                            map={mapArea}
                            service={service}
                            history={history}
                            closeDrawer={closeDrawer}
                            hideMapView={hideMapView}
                            showTooltip={this.showTooltip}
                            hideTooltip={this.hideTooltip}
                            openTooltip={this.state.openTooltip}
                            query={this.props.query}
                            clickEvent={this.clickEvent}
                            click={this.state.click}
                            category={this.props.match.params.category}
                            services={services}
                            />
                        )}
                </GoogleMapReact>
                </div>
            </div>  
        );
    }
}

export default withRouter(withStyles(styles)(MapView));
